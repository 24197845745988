<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'单位物联设备查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="searchBox"
      v-watermark
    >
      <form action="/">
        <van-search
          :value="$route.query.text"
          show-action
          left-icon=""
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                :value="+$route.query.type"
                :options="option1"
                disabled
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="systemCode.length"
      >
        <van-cell-group>
          <van-cell
            :title="item.systemType"
            is-link
            v-for="(item, index) in systemCode"
            :key="index"
            @click="clickHandler(item)"
            center
          >
            <template #default>
              <p v-if="item.systemTypeCount">
                <span :style="{ color: '#1990ff' }">{{
                  item.systemTypeCount
                }}</span>
                <span>条</span>
              </p>
              <p v-else>
                <span>
                  0条
                </span>
              </p>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { getCompanyInternetClassList } from '@/api/companyInternet'

export default {
  name: 'companyAddressInfo',
  data() {
    return {

      option1: [
        { text: '设备ID', value: 0 },
        { text: '单位名', value: 1 },
        { text: '编号', value: 2 },
        { text: '卡号', value: 4 }
      ],
      loadingShow: false,
      systemCode: []
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/companyInternetResult')) {
      next((vm) => {
        if (!vm.systemCode.length) {
          vm.getCompanyInternetClassList()
        }
      })
    } else {
      next((vm) => {
        vm.systemCode = []
        vm.getCompanyInternetClassList()
      })
    }
  },
  methods: {
    // 获取单位物联分类
    async getCompanyInternetClassList() {
      this.loadingShow = true
      try {
        const { data } = await getCompanyInternetClassList({
          type: +this.$route.query.type,
          search: this.$route.query.text,
          fuzzy: +this.$route.query.type === 2 || +this.$route.query.type === 0 ? 0 : 1
        })
        this.systemCode = [...data.data]
        this.loadingShow = false
      } catch (error) {
        this.loadingShow = false
        if (error?.response?.status === 401) {
          return
        }
        if (this.$route.path === '/workTable/companyInternetSystemCode') {
          if (error.message.includes('timeout')) {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
            return
          }
          this.$toast.fail({
            message: '当前网络异常，请稍后再试!!',
            duration: 500
          })
        }
      }
    },
    // 大类跳转
    clickHandler(val) {
      if (val.systemTypeCount) {
        this.$router.push({
          path: '/workTable/companyInternetResult',
          query: {
            text: this.$route.query.text,
            type: this.$route.query.type,
            systemTypeCode: val.systemTypeCode
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .blue {
      color: #1990ff;
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__content {
        padding-left: 0;
      }

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;

          .van-ellipsis {
            padding-right: 5px;
            font-size: 14px;
          }
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 25px;
              font-size: 14px;
            }
          }
        }
      }

      .van-dropdown-menu__item--disabled {
        .van-dropdown-menu__title {
          color: #323233;
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 24px - 54px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
